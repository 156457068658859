
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { sampleColors } from "@/models/point";
import { generateSVGImage } from "@/generative/splatter";
import Mint from "@/components/Mint.vue";
import ProviderView from "@/components/ProviderView.vue";
import { addresses as localhost } from "@/utils/addresses/sample_localhost";
import { addresses as goerli } from "@/utils/addresses/sample_goerli";
import { Addresses } from "@/utils/addresses";

const allAddresses: Addresses = {
  localhost,
  goerli,
};

export default defineComponent({
  components: {
    Mint,
    ProviderView,
  },
  setup() {
    const route = useRoute();
    const network =
      typeof route.query.network == "string" ? route.query.network : "goerli";
    const addresses = allAddresses[network];
    const tokenAddress = addresses.sampleToken;
    console.log("*** chainId", network, tokenAddress);
    const images = ref<string[]>([]);
    const updateImages = () => {
      images.value = sampleColors.map((color) => generateSVGImage(color));
    };
    updateImages();
    return {
      images,
      updateImages,
      network,
      tokenAddress,
    };
  },
});
