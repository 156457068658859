
import { defineComponent } from "vue";
import References from "@/components/References.vue";
export default defineComponent({
  components: {
    References,
  },
  setup() {
    console.log("*** env", process.env.VUE_APP_ALCHEMY_API_KEY);
    return {};
  },
});
