import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-10 lg:flex" }
const _hoisted_2 = { class: "flex w-full flex-1 flex-col px-4 pb-4 lg:w-1/2" }
const _hoisted_3 = { class: "font-londrina mt-4 text-4xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("message.notFoundMessage")), 1)
    ])
  ]))
}