import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto max-w-3xl p-2 text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProviderView = _resolveComponent("ProviderView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ProviderView, {
      assetProvider: "star",
      debugMode: true
    }),
    _createVNode(_component_ProviderView, {
      assetProvider: "pnouns",
      debugMode: true,
      network: "mainnet",
      offset: 1000
    }),
    _createVNode(_component_ProviderView, {
      assetProvider: "paperNouns",
      debugMode: true,
      network: "mainnet",
      offset: 500
    })
  ]))
}