
import { defineComponent, ref, computed } from "vue";

// mint
import NetworkGate from "@/components/NetworkGate.vue";
import { BigNumber } from "ethers";
import { ChainIdMap, displayAddress } from "@/utils/MetaMask";
import {
  useMessageTokenNetworkContext,
  useMessageStoreNetworkContext,
  getProvider,
  getMessageTokenContract,
} from "@/utils/const";

//
import { parse } from "svg-parser";
import { convSVG2Path, dumpConvertSVG } from "@/utils/svgtool";
import { compressPath } from "@/utils/pathUtils";
import { addresses } from "@/utils/addresses";

export default defineComponent({
  components: {
    NetworkGate,
  },
  setup(props) {
    const message = ref("Fully On-chain\ntest.");
    const color = ref("orange");

    const colors = [
      "pink",
      "orange",
      "yellow",
      "green",
      "darkmagenta",
      "aquamarine",
      "gold",
      "deeppink",
      "indigo",
      "orangered",
      "red",
      "mediumblue",
      "palegreen",
      "cornflowerblue",
    ].map((c) => ({
      value: c,
      text: c,
    }));

    const network = "goerli";

    const tokenAddress = addresses.messageSplatter.goerli;

    const chainId = ChainIdMap[network];

    const { networkContext } = useMessageTokenNetworkContext(
      chainId,
      tokenAddress
    );

    const isMinting = ref<boolean>(false);
    const mint = async () => {
      if (networkContext.value == null) {
        return;
      }
      if (isMinting.value) {
        return;
      }
      const { contract } = networkContext.value;
      isMinting.value = true;

      const ret = {
        message: message.value,
        color: color.value,
      };
      try {
        const tx = await contract.functions.mintWithAsset(ret);
        console.log("mint:tx");
        const result = await tx.wait();
        console.log("mint:gasUsed", result.gasUsed.toNumber());
      } catch (e) {
        alert("We are sorry, but something went wrong.");
        isMinting.value = false;
        console.error(e);
      }
    };

    const alchemyKey = process.env.VUE_APP_ALCHEMY_API_KEY;
    const provider = getProvider(network, alchemyKey);
    const tokenContract = getMessageTokenContract(tokenAddress, provider);
    const tokens = ref<any[]>([]);

    const fetchTokens = () => {
      tokenContract.totalSupply().then(async (nextId: BigNumber) => {
        const token = nextId.toNumber() - 1;
        tokens.value = [];
        for (let i = 0; i < 10; i++) {
          if (token - i > 0) {
            const ret = await tokenContract.tokenURI(token - i);
            const data = JSON.parse(atob(ret.split(",")[1]));
            tokens.value.push(data);
          }
        }
      });
    };
    fetchTokens();

    provider.once("block", () => {
      tokenContract.on(
        tokenContract.filters.Transfer(),
        async (from, to, tokenId) => {
          isMinting.value = false;
          console.log("*** event.Transfer calling fetchTokens");
          fetchTokens();
        }
      );
    });

    return {
      message,
      color,
      colors,
      // mint
      mint,
      isMinting,
      chainId,

      tokens,
    };
  },
});
