
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import Mint from "@/components/Mint.vue";
import ProviderView from "@/components/ProviderView.vue";
import { addresses } from "@/utils/addresses";

export default defineComponent({
  components: {
    ProviderView,
    Mint,
  },
  setup() {
    const offset = ref<number>(0);
    const route = useRoute();
    const network =
      typeof route.query.network == "string" ? route.query.network : "mainnet";
    const tokenAddress = addresses.dotNounsToken[network];
    const tokenGateAddress = addresses.dynamic[network];
    const minted = async () => {
      console.log("***###*** minted event was fired");
    };

    console.log("*** chainId", network, tokenAddress);
    return {
      network,
      tokenAddress,
      tokenGateAddress,
      offset,
      minted,
    };
  },
});
