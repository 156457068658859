import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.images.length == 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, "(Fetching from the blockchain...)"))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
      return (_openBlock(), _createElementBlock("img", {
        key: image,
        src: image,
        class: "mr-1 mb-1 inline-block w-20"
      }, null, 8, _hoisted_2))
    }), 128))
  ]))
}