
import { defineComponent, ref } from "vue";
import { sampleColors } from "@/models/point";
import { generateSVGImage } from "@/generative/snow";
import ProviderView from "@/components/ProviderView.vue";

export default defineComponent({
  components: {
    ProviderView,
  },
  setup() {
    const images = ref<string[]>([]);
    const updateImages = () => {
      images.value = sampleColors.map((color) => generateSVGImage(color));
    };
    updateImages();
    return {
      images,
      updateImages,
    };
  },
});
